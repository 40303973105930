import React, { useContext, useState, useEffect } from 'react'

import { SiteContext, baseUrl } from '../App';

import MainRecords from '../records/MainRecords'
// import ClassPage from './ClassInformation'
import Dashboard from './Dashboard'
import Create from '../records/create'
import PQuiz from './PQuiz';
import SeatingChart from '../admin/seatingChart/SeatingChart';
import Agreements from '../admin/createAgreements/Agreements'
import D_Header from '../header/D_Header';
import ColorGrid from '../admin/colorGrid/ColorGrid';
import Grades from '../admin/grades/Grades'
import AStore from '../store/admin/AStore';
import GenInfo from './GenInfo';
import AddModal from '../store/admin/AddModal';
import FeelingPage from '../other/feelingsWheel/FeelingPage';
import HallPass from '../hallPass/admin/page/HallPass';
import Inventory from '../modules/inventory/admin/AInventory'
import SInventory from '../modules/inventory/student/SInventory'
import Auction from './Auction'
import Roster from '../admin/roster/Roster';
import Classes from '../admin/classes/Classes';
import Student from './Student';
import CustomModal from '../modals/customModal/viewModal/ViewModal';
import InventoryByDay from '../modules/inventory/admin/byDay/InventoryByDay';
import Checklist from '../modules/checklist/student/SChecklist'
import AChecklist from '../modules/checklist/admin/AChecklist';
import StoreItems from '../store/admin/storeItems/StoreItems';
import Events from './Events'
import Grid from './Grid'
import Modules from '../modules/page/Modules'
import Schedules from '../admin/schedulesCalendar/Schedules'
import WeekReview from '../weekReview/WeekReview';
import AudioUploader from "./AudioUploader";

import { useMongo } from '../files/adminContainer/useMongo';
import { useSocket } from '../files/adminContainer/socket';
import { useChips } from '../files/adminContainer/chips';
import { useBehavior } from '../behavior/dashboard/files/dbehavior';
import { useHallPass } from '../files/adminContainer/hallPass';
import { useAttendance } from '../files/adminContainer/attendance';
import { useModal } from '../files/adminContainer/modal';
import { useInventory } from '../files/adminContainer/inventory';
import { useModules } from '../files/adminContainer/modules';

import { ErrorBoundary } from '../other/utils/ErrorBoundary';

export const AdminContext = React.createContext()

const LOCAL_STORAGE_KEY_ADDDAILY = 'classWebsite.addDaily'
const LOCAL_STORAGE_KEY_ADDCHIPS = 'classWebsite.addChips'
const LOCAL_STORAGE_KEY_ORDERS = 'classWebsite.orders'
const LOCAL_STORAGE_KEY_DAILYLIST = 'classWebsite.dailyList'

export default function AdminContainer({ type, text }) {

    const {
        studentList, classInformation, classInformationArray, adminPages, focusAgreements, modalShowFunction, show,
        studentDataFunctions: {
            useCreateStorageState, useSaveLocalStorage, getMongoList, setShow, handleClose, handleShow,
        }
    } = useContext(SiteContext)

    const newAddChips = []
    const newAddDaily = []
    classInformationArray.map(entry => {
        newAddChips.push({period: entry.period, chips: [{variant: 'danger', timeStamp: '15'}, {variant: 'danger', timeStamp: '30'}, {variant: 'danger', timeStamp: '45'}]})
        newAddDaily.push({period: entry.period, dailyEventSubmitted: false})
    })

    const [dailyRecord, setDailyRecord] = useState([])
    const [showMenu, setShowMenu] = useState([])
    const [dailyList, setDailyList] = useState(useCreateStorageState(LOCAL_STORAGE_KEY_DAILYLIST, []))
    const [listUpdate, setListUpdate] = useState(false)
    const [addDaily, setAddDaily] = useState(useCreateStorageState(LOCAL_STORAGE_KEY_ADDDAILY, newAddDaily))
    const [addChips, setAddChips] = useState(useCreateStorageState(LOCAL_STORAGE_KEY_ADDCHIPS, newAddChips))
    // const [orders, setOrders] = useState(useCreateStorageState(LOCAL_STORAGE_KEY_ORDERS, []))
    const [orders, setOrders] = useState([])
    const [updateOrders, setUpdateOrders] = useState(false)
    // const [show, setShow] = useState(modalShowFunction());
    const [update, setUpdate] = useState(0)
    const [modes, setModes] = useState(modesFunction())

    const { updateDailyListMongo, handleAddDailyRecord, clearDailyList, createCleanStudent, updateStudentListWithDaily, handlePeriodSelect } 
    = useMongo({ dailyRecord, setDailyRecord, listUpdate, setDailyList, addDaily, setAddDaily, setAddChips,
                newAddChips, newAddDaily})

    const { behaviorEvents, setBehaviorEvents, lastUpdateTime, setLastUpdateTime, handleDeleteDuplicateEvents, updateBEvents, setUpdateBEvents,
        pendingTime, setPendingTime, pendingEvents, setPendingEvents, deleteBehaviorEvent, handleColorClick, filterEvents } 
    = useBehavior({})    

    const { handleAttendance, handleAttendanceAll, handleAttendanceClick, handleAttendanceStatus } 
    = useAttendance({updateDailyListMongo})

    const { updateHPEvents, setUpdateHPEvents, hpEvents, setHpEvents, updateHallPassEvent, deleteHallPassEvent, convertTimeString,
        hpMessage, setHpMessage, updateExistingEvent, hpEventsByPeriod, calcHallPassMath, getHallPassEvents } 
    = useHallPass()

    const { moduleItems, setModuleItems, moduleField, setModuleField, checkoutItems, setCheckoutItems } 
    = useInventory()

    const {handleOverride, 
        // handleClose, handleShow, 
        handleResetModal, mode, setMode, chooseMode}
    = useModal({setShow, setUpdate})

    const {handleModifyModuleGroup, handleDate}
    = useModules({})
    
    const { refreshAllStudents, connected, psOutcome } 
    = useSocket({orders, setOrders, setUpdateOrders, setUpdateHPEvents, setUpdateBEvents, type, 
        moduleItems, setModuleItems, checkoutItems, setCheckoutItems, handlePeriodSelect, handleShow,
        behaviorEvents, setBehaviorEvents, handleAttendance })

    const [agreements, setAgreements] = useState(classInformation.agreements)

    useEffect(() => {
        getMongoList('orders').then(orders => {
            if(!orders) return
            setOrders(orders)
        })
    }, [updateOrders])

    const { handleAddChips, showChipModal, handleChipModal, handleChipClose, handleAddChipsManual, handleUpdateOrders } 
    = useChips({ dailyList, addChips, setAddChips, handleAttendance, setDailyList, orders, setOrders })
    
    useSaveLocalStorage(LOCAL_STORAGE_KEY_ORDERS, orders)
    useSaveLocalStorage(LOCAL_STORAGE_KEY_ADDDAILY, addDaily)
    useSaveLocalStorage(LOCAL_STORAGE_KEY_ADDCHIPS, addChips)

    useEffect(() => {
        const showMenuArray = []
        studentList.map(entry => {
            showMenuArray.push({
                name: entry.name, studentId: entry.studentId, showMenu: 'hide'
            })
        })
        setShowMenu(showMenuArray)
    }, [studentList.length])

    useEffect(() => {
        document.title = process.env.NODE_ENV === 'development' && text ? 'D ' + text : document.title
    }, [type])

    function handleUpdateList() {
        setListUpdate(!listUpdate)
    }

    // console.log(focusAgreements,'focusAgreements')

    const adminVariables = 
    {
        dailyRecord, clearDailyList, dailyList, setDailyList, showMenu, setShowMenu, updateDailyListMongo, handleUpdateList, refreshAllStudents,

        hpEvents, setHpEvents, updateHPEvents, setUpdateHPEvents, hpMessage, setHpMessage, updateExistingEvent, hpEventsByPeriod, updateHallPassEvent, deleteHallPassEvent,
        agreements, setAgreements, handleUpdateList,
    
        orders, setOrders, handleAddChips, handleAddChipsManual, addChips, showChipModal, handleChipModal, handleChipClose, handleUpdateOrders,

        handleAttendance, handleAttendanceAll, handleAttendanceClick, handleAttendanceStatus,

        behaviorEvents, setBehaviorEvents, lastUpdateTime, setLastUpdateTime, updateBEvents, setUpdateBEvents, filterEvents,
        pendingTime, setPendingTime, pendingEvents, setPendingEvents, handleDeleteDuplicateEvents, deleteBehaviorEvent,

        createCleanStudent, updateStudentListWithDaily, convertTimeString, calcHallPassMath,

        handleColorClick, handleOverride, handleClose, handleShow, connected, handleResetModal,

        moduleItems, setModuleItems, moduleField, setModuleField, checkoutItems, setCheckoutItems, modes, setModes,

        mode, setMode, chooseMode, modesFunction, modalModes: modes,

        handleModifyModuleGroup, setAddDaily, handleDate
    }

    return (
      <ErrorBoundary>
        <div className="app-container app-theme-white body-tabs-shadow fixed-header">
        <AdminContext.Provider value={adminVariables}>
            {/* {process.env.NODE_ENV !== 'development' &&  */}
            {type === 'dashboard' 
            // && process.env.NODE_ENV !== 'development' 
            && <AddModal/>}
            {/* } */}
            <D_Header type={type} handleAddDailyRecord={handleAddDailyRecord} handleUpdateList={handleUpdateList} handleAddChips={handleAddChips} addDaily={addDaily} type={type}
              addChips={focusAgreements.chips} handlePeriodSelect={handlePeriodSelect} handleClose={handleClose} handleShow={handleShow} show={show} update={update} psOutcome={psOutcome}
            />
            <div className="app-main">
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <ErrorBoundary>
                        {/* {useNetwork() === false ? <NoConnection/> :  */}
                        <>
                        {type === 'dashboard' && <Dashboard/>}
                        {type === 'records' && <MainRecords/>}
                        {type === 'createRecord' && <Create/>}
                        {type === 'pquiz' && <PQuiz/>}
                        {type === 'agreements' && <Agreements/>}
                        {type === 'seatingChart' && <SeatingChart/>}
                        {type === 'colorGrid' && <ColorGrid/>}
                        {type === 'grades' && <Grades/>}
                        {type === 'astore' && <AStore/>}
                        {type === 'scheduleAdmin' && <GenInfo/>}
                        {type === 'feelingsWheel' && <FeelingPage/>}
                        {type === 'hallPass' && <HallPass/>}
                        {type === 'inventory' && <Inventory/>}
                        {type === 'inventoryStudent' && <SInventory/>}
                        {type === 'auction' && <Auction/>}
                        {type === 'roster' && <Roster/>}
                        {type === 'classes' && <Classes/>}
                        {type === 'studentDash' && <Student/>}
                        {type === 'customModal' && <CustomModal/>}
                        {type === 'inventoryByDay' && <InventoryByDay/>}
                        {type === 'achecklist' && <AChecklist/>}
                        {type === 'storeItems' && <StoreItems/>}
                        {type === 'events' && <Events/>}
                        {type === 'grid' && <Grid/>}
                        {type === 'modules' && <Modules/>}
                        {type === 'schedules' && <Schedules/>}
                        {type === 'colorReviewAdmin' && <WeekReview/>}
                        {type === 'audioUploader' && <AudioUploader baseUrl={baseUrl}/>}
                        </>
                        {/* }  */}
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
        </AdminContext.Provider>
        </div>
      </ErrorBoundary>    
    )
}

function modesFunction() {

    const customModalOptions = [
        {type: 'attendanceQuestion', text: 'Attendance Question'},
        {type: 'createAgreement', text: 'Create Agreement'}, 
        {type: 'dailyLinks', text: 'Daily Links'},
        {type: 'readingZone', text: 'Reading Zone'},
        {type: 'assignments', text: 'Assignments'},
    ]

    return [
        {type: 'customModal', text: 'Custom Modal', variant: 'success', active: 'attendanceQuestion', options: customModalOptions},
        {type: 'goalSetting', text: 'Goal Setting', variant: 'primary'},
        {type: 'dayReview', text: 'Exit Ticket', variant: 'info'},
    ]
}

function useNetwork() {
    const [isOnline, setNetwork] = useState(window.navigator.onLine);
  
    const updateNetwork = () => {
      setNetwork(window.navigator.onLine);
    };
  
    useEffect(() => {
      window.addEventListener("offline", updateNetwork);
  
      window.addEventListener("online", updateNetwork);
  
      return () => {
        window.removeEventListener("offline", updateNetwork);
  
        window.removeEventListener("online", updateNetwork);
      };
    });
  
    return isOnline;
  }

  function NoConnection({}) {
      return <div>
          <div className="badge bg-secondary">No Internet Connection</div>
      </div>
  }